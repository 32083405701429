import React, { useState, useContext, useEffect } from "react";
import {Nav,Tab} from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { ThemeContext } from "../../context/ThemeContext";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Setting = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleLanClick = (divLang) => {
    localStorage.setItem('selectedLanguage', divLang);
    window.location.reload();
  };

  let  divLang;
  const sel_Language = localStorage.getItem('selectedLanguage');
  if (sel_Language !== null) {
   divLang = sel_Language;
  } else {
   divLang = "en";
  }
  const [selectedLanguage, setSelectedLanguage] = useState(divLang);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };


  const handleCarClick = (divCarr) => {
    localStorage.setItem('selectedCurrency', divCarr);
      let  cur_icon;
	  if (divCarr === "USD") {
	   cur_icon = "$";
	  } else if (divCarr === "EUR"){
	   cur_icon = "€";
	  } else if (divCarr === "BDT"){
	   cur_icon = "৳";
	  } else if (divCarr === "INR"){
	   cur_icon = "₹";
	  }
    localStorage.setItem('CurrencySymbol', cur_icon);
    window.location.reload();
  };
  let  divCarr;
  const sel_cudrrency = localStorage.getItem('selectedCurrency');
  if (sel_cudrrency !== null) {
   divCarr = sel_cudrrency;
  } else {
   divCarr = "USD";
  }
  const [selectedCurrency, setSelectedCurrency] = useState(divCarr);
  
  const [settingToggle, setSettingToggle] = useState(false);
  const [demoToggle, setDemoToggle] = useState(false);
  const {
    body,
    backgroundOption,
    changeBackground,
    background
  } = useContext(ThemeContext);
  return (
	<>

	
	</>
  );
};

export default Setting;


