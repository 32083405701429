import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sportsApi } from "../../../services/SportsService";
import { useWebsocket } from "../../../context/webSocket";
import BetSlipComponent from "./betslip";
import { getCustIpAddress, getIpAddress } from "../../../services/AuthService";
import axios from "axios";
import { isMobile } from "react-device-detect";
import Fancy from "./fancy";
import { useWebsocketUser } from "../../../context/webSocketUser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PnlMarket from "./pnl";
import BetslipModalComponent from "./betslipModal";

const Odds = () => {
    const type = useParams();
    const [activetab, setactivetab] = useState("Inplay")
    const [matchList, setMatchList] = useState([])
    const [matchInfo, setMatchInfo] = useState({})
    const [betSelection, setBetSelection] = useState({})
    const { socket } = useWebsocket()
    const { socketUser } = useWebsocketUser()
    const [odds, setOdds] = React.useState({})
    const { matchId, status } = useParams()
    const [user, setUser] = useState(null);
    const [bethistory, setbethistory] = useState([])
    const [marketBook, setMarketBook] = useState({})

    const [fancies, setMarketDataList] = React.useState([])
    const [fancyType, setFancyType] = React.useState("session")
    const [ActiveTabOdds, setActiveTabOdds] = React.useState("ODDS")

    useEffect(() => {
        const auth_token = localStorage.getItem('auth_token');
        const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
        if (auth_token !== null) {
            axios.post('/apps-profile', data)
                .then(response => {
                    setUser(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])


    const fetchMatchList = async () => {
        if (!matchId) return
        sportsApi.get(`get-market-list?matchId=${matchId}&fancyType=${fancyType}`).then((response) => {
            console.log(response)
            if (response.data.data) {
                setMatchList(response.data.data.markets)
                setMatchInfo(response.data.matchInfo)
                marketIdsEvent(response.data.data.markets)
            }
        })
        fetchMatchFancyList()


    }
    const fetchMatchFancyList = async () => {
        sportsApi.get(`get-fancy-list?matchId=${matchId}&fancyType=${fancyType}`).then((response) => {
            if (response.data.data) {
                setMarketDataList(response.data.data.fancy)
            }
        })
    }

    const fetchbetList = async () => {
        if (!matchId) return
        const response = await sportsApi.get(`get-bet-list?matchId=${matchId}&status=pending`)
        if (response.data.data) {
            setbethistory(response.data.data?.markets)
            setMarketBook(response.data.data?.book)
        }
    }

    useEffect(() => {
        fetchMatchList()
        getIpAddress();
        fetchbetList();
        return () => {
            socket.off('getMarketData')
            leaveMarketRoom()
        }
    }, [matchId])

    useEffect(() => {
        fetchMatchFancyList()
    }, [fancyType])


    const marketIdsEvent = (data) => {
        const selections = {}
        const profitLoss = {}
        const remarkMarket = {}
        let runnersName = {}
        data.forEach(async (market) => {
            const runners = JSON.parse(market.runnners)
            runners.forEach((runner) => {
                runnersName = {
                    ...runnersName,
                    [market.marketId]: {
                        ...runnersName[market.marketId],
                        [runner.selectionId]: runner.runnerName,
                    },
                }
                selections[market.marketId] = market
                profitLoss[runner.selectionId] = 0
            })
            remarkMarket[market.marketId] = ''
        })
        const state = {
            runnersData: selections,
            runnersDataPrev: JSON.parse(JSON.stringify(selections)),
            profitLoss,
            getMarketBook: {},
            remarkMarket: remarkMarket,
            runnersName: runnersName,
        }
        setOdds(state)
        setTimeout(() => {
            socketEvents()
        }, 1000)
    }
    const onBet = (isBack = false, back, stack = 0, market, runner) => {
        console.log(isBack, back, stack, market, runner)
        const ipAddress = getCustIpAddress()
        if (back.price > 0 && back.size) {
            let json = {
                isBack,
                odds: market.oddsType == "" ? back.price1 : parseFloat(back.price.toFixed(4)),
                volume: back.size,
                marketId: market.marketId,
                marketName: market.marketName,
                matchId: market.matchId,
                selectionName: runner.name,
                selectionId: runner.selectionId,
                pnl: 0,
                stack: stack,
                currentMarketOdds: market.oddsType == "" ? back.price1 : back.price,
                eventId: market.sportId,
                exposure: -0,
                ipAddress: ipAddress,
                type: "match",
                matchName: matchInfo.match_name,
                betOn: "MATCH_ODDS",
                oddsType: market.oddsType,
            }
            setBetSelection(json)
        }
    }
    const onBetFancy = (isBack = false, back) => {
        const ipAddress = getCustIpAddress()
        if (back.BackPrice1 <= 0 && back.BackSize1 <= 0 && isBack) return
        if (back.LayPrice1 <= 0 && back.LaySize1 <= 0 && !isBack) return


        let json = {
            isBack,
            odds: isBack ? back.BackPrice1 : back.LayPrice1,
            volume: isBack ? back.BackSize1 : back.LaySize1,
            marketId: back.SelectionId,
            marketName: back.gtype,
            matchId: back.match_api_id,
            selectionName: back.RunnerName,
            selectionId: back.SelectionId,
            pnl: 0,
            stack: 0,
            currentMarketOdds: isBack ? back.BackPrice1 : back.LayPrice1,
            eventId: 4,
            exposure: -0,
            ipAddress: ipAddress,
            type: "match",
            matchName: matchInfo.match_name,
            betOn: "FANCY",
            oddsType: back.gtype,
            fancystatus: "yes"
        }
        setBetSelection(json)
    }
    const getSocketEvents = () => {
        const handler = (market) => {
            setOdds((prev) => ({
                ...prev,
                runnersData: { ...prev.runnersData, [market.marketId]: market },
            }))
        }
        socket.on('getMarketData', handler)
    }

    const availableToBack = (selections, marketData, runner) => {
        return selections?.map((back, index) => {
            let cls = index == 0 ? 'back-3' : `back-2`
            cls = index == 1 ? 'back-2  hide-on-small-only' : cls
            cls = index == 2 ? 'back-1s ' : cls
            //   const blinkCls = back.changed ? 'blink' : ''
            if (isMobile && index != 2) return
            return (
                <td value={back.price} onClick={() => {
                    onBet(true, back, 0, marketData, runner)
                }} id={`back_odds_${back.price}`} className={`${cls} bet-cursor border-0 false`} style={{ cursor: "hand" }}><a>{back.price}<span>{back.size}</span></a></td>
            )
        })
    }

    const availableToLay = (selections, marketData, runner) => {
        return selections?.map((lay, index) => {
            const i = index
            let cls = index == 0 ? 'lay-1' : `lay-${index}`
            cls = index == 1 ? 'lay-2  hide-on-small-only' : cls
            cls = index == 2 ? 'lay-3  hide-on-small-only' : cls
            //   const blinkCls = lay.changed ? 'blink' : ''
            if (isMobile && index != 0) return
            return (
                <td value={lay.price} onClick={() => {
                    onBet(false, lay, 0, marketData, runner)
                }} id={`lay_odds_${lay.price}`} className={`${cls} bet-cursor border-0 false`} style={{ cursor: "hand" }}><a>{lay.price}<span>{lay.size}</span></a></td>
            )
        })
    }
    const renderRunners = (runners, selectionIdName, marketData) => {
        return runners?.map((Item) => {
            const name = selectionIdName?.[Item.selectionId]
            Item['name'] = name
            return <tr className="relative_row">
                <th className="">
                    <p>
                        <a href="#"><img className="icon-predict" src="../../imgs/transparent.gif" /></a>{name}
                        <PnlMarket marketBook={marketBook} selectionId={Item.selectionId} marketId={marketData.marketId} />
                    </p>
                </th>
                <td className="relative_for p-0">
                    <table>
                        <tr>
                            {availableToBack(Item?.ex?.availableToBack, marketData, Item)}
                        </tr>
                    </table>
                    {Item.status == "SUSPENDED" && <div class="hide-data-table ps-0"><table class="w-100 text-center"><tbody><tr><td class="pe-xxl-5 pe-3">Suspended</td></tr></tbody></table></div>}

                </td>
                <td className="relative_for p-0">
                    <table>
                        <tr>
                            {availableToLay(Item?.ex?.availableToLay, marketData, Item)}
                        </tr>
                    </table>
                </td>
            </tr>
        })
    }
    const renderSports = (sportId) => {
        return matchList?.map((Item) => {
            const runner = JSON.parse(Item.runnners)
            const oddsDataNew = odds ? odds?.runnersData?.[Item.marketId] : null
            const selectionIdName = odds?.runnersName?.[Item.marketId]
            return <div><div className="top-wrapper-match bg-white mb-3 mt-2">
                <ul className="match-btn d-flex justify-content-center">
                    <li><a className="btn-pin" href="#"><img src="/static/media/pin1.3787342a9251a038ff5fa4c6954f71d4.svg" alt="" /></a></li>
                    <li><a className="btn-refresh" href="#"> <img src="/static/media/refresh.d1ac819a6c793c0332d045380510641e.svg" alt="" /></a></li>
                </ul>
                <div className="">
                    <div className="match-top-wrapper d-flex align-items-center">
                        <div className="odd-sec"><strong>{Item.marketName}</strong><span> In-Play</span></div>
                        <div className="max">
                            <h6 className="mb-0">Max 5000.00</h6>
                        </div>
                        <div className="live-match"><span>Matched</span><strong>USD 44433.24</strong></div>
                    </div>
                </div>
            </div>
                <div className="match-odds-table game_table_main_wrapper">
                    <table className="bets game_table_inner">
                        <tbody>
                            <tr className="bet-all" style={{ background: "rgb(240, 236, 225)" }}>
                                <td className="text-start">{Item.marketName}</td>
                                <td className="px-0">
                                    <table>
                                        <tr>
                                            <td id="backPercent" className="refer-bet mobile_hide">100.6%</td>
                                            <td className="back-all_td"><a id="backAll" className="back-all"><i className="backall-shape"></i><span>Back all</span></a></td>
                                        </tr>
                                    </table>
                                </td>
                                <td className="px-0">
                                    <table>
                                        <tr>
                                            <td className="lay-all_td"><a id="layAll" className="lay-all"><i className="layall-shape"></i><span>Lay all</span></a></td>
                                            <td id="layPercent" className="refer-book mobile_hide" colspan="2">99.9%</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            {renderRunners(oddsDataNew?.runners, selectionIdName, Item)}
                        </tbody>
                    </table>
                </div></div>
        })
    }
    const socketEvents = useCallback(() => {
        // Define your socket logic
        leaveMarketRoom()
        joinMarketRoom()
        getSocketEvents()
        socket.on('connect', () => {
            joinMarketRoom()
        })
        socket.on('reconnect', () => {
            joinMarketRoom()
        })
    }, []);

    const joinMarketRoom = () => {
        if (matchId) {
            socket.emit('joinRoom', matchId)
        }
        matchList.forEach(async (market) => {
            socket.emit('joinMarketRoom', market.marketId)
        })
    }
    const leaveMarketRoom = () => {
        matchList.forEach(async (market) => {
            socket.emit('leaveRoom', market.marketId)
            socket.emit('leaveRoom', market.matchId)
        })
    }
    const closeModal = () => {
        setBetSelection({})
    }
    return (
        <div>
            <div className="full-wrap">
                <div className="match-sidebar">
                    <ul className="mb-0">
                        <div>
                            <h6>Sports</h6>
                            <li><Link to="/sportsbook">All Sports</Link> </li>
                            <li className=""><Link to="/sport-sbook/cricket">Cricket</Link> </li>
                            <li className=""><Link to="/sport-sbook/soccer">Soccer</Link> </li>
                            <li className=""><Link to="/sport-sbook/tennis">Tennis</Link> </li>
                            <div>
                                <li><Link to="/markets/cricket/">{matchInfo?.match_series_name}</Link> </li>
                                <li className="active"><Link to={`/markets/${matchId}`}>{matchInfo?.match_name}</Link> </li>
                                {matchList.length > 0 && matchList?.map((Item) => {
                                    return <li className="">
                                        <Link to={`/markets/${matchId}`}>
                                            <img className="icon-no_play" src="../assets/images/transparent.gif" />{Item.marketName}
                                        </Link> </li>
                                })}
                            </div>
                            <div></div>
                        </div>
                    </ul>
                </div>
                <div className="col-center ">
                    <div className="button-tabs">
                        {isMobile && <div class="mbettab">
                            <div class={`mbettab-box ${ActiveTabOdds=="ODDS" ? 'active':''}`} onClick={() => setActiveTabOdds("ODDS")}>ODDS</div>
                            <div class={`mbettab-box ${ActiveTabOdds=="TV" ? 'active':''}`} onClick={() => setActiveTabOdds("TV")}>TV</div>
                            <div class={`mbettab-box ${ActiveTabOdds=="MyBets" ? 'active':''}`} onClick={() => setActiveTabOdds("MyBets")}>My Bets</div>
                        </div>}
                    </div>
                    {(ActiveTabOdds == "ODDS" || ActiveTabOdds == "TV") && <div>
                       
                        <div className="py-2">
                            <iframe className="responsive-iframe w-100" src={`https://score.hr08bets.in/api?eventid=${matchId}`} style={{ height: "180px" }}>
                            </iframe>
                        </div>
                        {(ActiveTabOdds=="TV" || !isMobile) && <div className="py-2">
                            <iframe style={{background:"#000", height: "280px"}} className="responsive-iframe w-100" 
                            src={matchInfo?.sportId==4?`https://hr08bets.in/cricket-stream-live/index.html?eventid=${matchId}`:
                            `https://hr08bets.in/sports-stream-live/index.html?eventid=${matchId}`} >
                            </iframe>
                        </div>}
                        {matchList?.length > 0 && renderSports()}
                        <div className="sportsbook-head  mt-3 d-flex align-items-center "></div>
                        {matchInfo.match_sports_id == 4 && fancies?.length > 0 && (
                            <Fragment>
                                {console.log(fancies)}
                                {/* @ts-expect-error */}
                                {<Fancy setFancyType={setFancyType} fancyType={fancyType} socketUser={socketUser} fancies={fancies} matchId={matchId} currentMatch={matchInfo} getMarketBook={marketBook} setBetSelection={setBetSelection} onBet={onBetFancy} />}
                            </Fragment>
                        )}
                    </div>}
                    {ActiveTabOdds == "MyBets" && <div className="ovx">
                        <table className="table table-bordered">
                            <tr className="bet_list_header"><th className="text-left"> Runner</th><th> Stake</th><th> Rate</th><th> pl</th><th> Place Date</th><th> Match Date</th></tr>
                            {bethistory?.map((Item) => {
                                return <tr className={`${Item?.isBack == 1 ? 'back' : 'lay'}`}><td className="no-wrap"> {Item.selectionName} </td><td class="no-wrap"> {Item.stack} </td><td class="no-wrap text-center">  {Item.odds} </td>
                                              <td class="no-wrap text-center">{Item.isBack==1?Item.pnl:Item.loss} </td>
                                <td class="no-wrap"> {Item.created_at} </td><td class="no-wrap"> {Item.matchedDate} </td></tr>
                            })}
                        </table>
                    </div>}
                </div>
                <button className="rightbar_btn d-block d-lg-none"><i className="fa-solid fa-bars"></i></button>
                <div className="col-right">
                    <div className="theme_accordian">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Bet Slip</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bet_slip_data">
                                            <div className="col-right-inner">
                                                <div className="message-bet d-none">
                                                    <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
                                                    <p id="betMsg" className="warning"></p>
                                                </div>
                                                <p className="d-none">Click on the odds to add selections to the betslip.</p>
                                                <div id="betSlipBoard" className="bet_slip">
                                                    <div id="betList" className="slip-list max-heightminus131">
                                                        <div className="bets_list_wrapper"><div></div></div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center align-item-center mt-2 px-3"><p >Click on the odds to add selections to the betslip.</p></div>
                                            </div>
                                        </div>

                                        {!isMobile && <BetSlipComponent betSelection={betSelection} setBetSelection={setBetSelection} user={user} bethistory={bethistory} fetchbetList={fetchbetList} />}

                                        {isMobile && <BetslipModalComponent showModal={betSelection.selectionId ? true : false} closeModal={closeModal} betSelection={betSelection} setBetSelection={setBetSelection} user={user} bethistory={bethistory} fetchbetList={fetchbetList} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>

        </div>
    );
};

export default Odds;
