import React, { useCallback, useEffect, useState } from "react";
import { useParams, Router, useLocation } from "react-router-dom";
import { sportsApi } from "../../../services/SportsService";
import { useWebsocket } from "../../../context/webSocket";
import moment from 'moment';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// Get the current date and time
const now = moment();
console.log("Current Date and Time:", now.format('YYYY-MM-DD HH:mm:ss'));

// Check if a date is before or after another
const matchDate = "2025-01-05T22:00:00.000Z";
console.log("Is match live?", moment().isBetween(matchDate, moment(matchDate).add(2, 'hours')));

const BetTechComponent = () => {
    const type = useParams();
    const [activetab, setactivetab] = useState("Inplay")
    const [matchList, setMatchList] = useState({ 4: [], 1: [], 2: [] })
    const { socket } = useWebsocket()
    const [odds, setOdds] = React.useState({})
    const [sereis, setSeries] = React.useState({})

    const { sportsId, seriesname } = useParams()
    console.log(sportsId, "sportsId")
    const isEventLive = (matchDateTime, durationHours = 2) => {
        const now = new Date(); // Current time
        const startTime = new Date(matchDateTime); // Event start time  
        return now >= startTime;
    }
    const fetchMatchList = async () => {
        let convertSportId = ""
        if(sportsId)
        {
            convertSportId = sportsId=="cricket" ? 4 : convertSportId
            convertSportId = sportsId=="soccer" ? 1 : convertSportId
            convertSportId = sportsId=="tennis" ? 2 : convertSportId
        }
        const response = await sportsApi.get(`get-match?sportId=${sportsId}&status=all&limit=`)
        if (response.data.data) {
            const filterData = { 4: [], 1: [], 2: [] }
            response.data.data?.map((Item) => {
                Item["isLive"] = isEventLive(Item.match_datetime);
            if(Item.match_sports_id==convertSportId)
            {
                filterData[Item.match_sports_id].push(Item)
            }
            })
            const oddsData = { ...odds };
            marketIdsEvent(response.data.data, oddsData, 'joinMarketRoom');
            setOdds(oddsData);
            setMatchList(filterData)
            renderSereies(filterData)
        }
    }
    useEffect(() => {
        fetchMatchList()
        return () => {
            const oddsData = { ...odds };
            marketIdsEvent(matchList, oddsData, 'leaveMarketRoom');
        };
    }, [activetab, sportsId])

    const marketIdsEvent = (data, oddsData, event) => {
        if (data && data?.length > 0) {
            data?.map((match) => {
                oddsData[match.market_id] = Array(6).fill('-')
                if (match.market_id) {
                    socket.emit(event, match.market_id)
                }
            })
        }

    }

    React.useEffect(() => {
        socket.on('getMarketData', (marketData) => {
            let firstIndexFirst = '-'
            let firstIndexTwo = '-'
            let secIndexFirst = '-'
            let secfirstIndexTwo = '-'
            let thirdIndexFirst = '-'
            let thirdfirstIndexTwo = '-'
            if (marketData.runners) {
                if (marketData.runners[0] && marketData.runners[0].ex.availableToBack[0]) {
                    firstIndexFirst = marketData.runners[0].ex.availableToBack[0].price
                    firstIndexTwo = marketData.runners[0].ex.availableToLay[0].price
                }

                if (marketData.runners[1] && marketData.runners[1].ex.availableToBack[0]) {
                    secIndexFirst = marketData.runners[1].ex.availableToBack[0].price
                    secfirstIndexTwo = marketData.runners[1].ex.availableToLay[0].price
                }

                if (marketData.runners[2] && marketData.runners[2].ex.availableToBack[0]) {
                    thirdIndexFirst = marketData.runners[2].ex.availableToBack[0].price
                    thirdfirstIndexTwo = marketData.runners[2].ex.availableToLay[0].price
                }
            }

            setOdds((prev) => ({
                ...prev,
                [marketData.marketId]: [
                    firstIndexFirst,
                    firstIndexTwo,
                    thirdIndexFirst,
                    thirdfirstIndexTwo,
                    secIndexFirst,
                    secfirstIndexTwo,
                ]
            }))
        })
        return () => {
            socket.off('getMarketData')
        }
    }, [odds])

    const memoOdds = useCallback(
        (marketId) => {
            console.log(odds)
            const marketData = odds[marketId]
            return (
                <>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[0]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[1]}</button>
                    </dd>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[2]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[3]}</button>
                    </dd>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[4]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[5]}</button>
                    </dd>
                </>
            )
        },
        [odds],
    )
    const renderSports = () => {
        let convertSportId = ""
        if(sportsId)
        {
            convertSportId = sportsId=="cricket" ? 4 : convertSportId
            convertSportId = sportsId=="soccer" ? 1 : convertSportId
            convertSportId = sportsId=="tennis" ? 2 : convertSportId
        }
        return matchList[convertSportId]?.map((Item) => {
            if(seriesname && seriesname!=Item.match_series_name ) return
            return <dl className="game-list-col d-flex">
                <dt className="col-matched">
                    <Link to={`/markets/${Item.match_api_id}`}>
                        <img className={`${Item.isLive ? 'icon-in_play' : ''}`} src="../../imgs/transparent.gif" alt="No_Image" />
                        <span className="game-titlesmall">{Item.match_name}</span>
                        {Item.isLive && <><span className="in_play">In-Play </span><span className="game-live"></span></>}
                        {!Item.isLive && <span class="game-list-time">{moment(Item.match_datetime).format("YYYY-MM-DD HH:mm")}</span>}
                    </Link>
                </dt>
                <dd className="col-mdl text-end">USD 0</dd>
                {memoOdds(Item?.market_id)}
                <dd className="col-info"><a className="add-pin" href="/inplay"></a></dd>
            </dl>
        })
    }
    const renderSereies = (filterData) => {
        let convertSportId = ""
        if(sportsId)
        {
            convertSportId = sportsId=="cricket" ? 4 : convertSportId
            convertSportId = sportsId=="soccer" ? 1 : convertSportId
            convertSportId = sportsId=="tennis" ? 2 : convertSportId
        }
        let itemNew = []
        filterData[convertSportId]?.map((Item) => {
            if(Item.match_series_name!="" && itemNew.indexOf(Item.match_series_name)<=-1)
            {
                itemNew.push(Item.match_series_name)
            }
        })
        setSeries(itemNew)
    }
    return (
        <div>
            <div className="full-wrap ">
                  <div className="match-sidebar">
                                    <ul className="mb-0">
                                        <div>
                                            <h6>Sports</h6>
                                            <li><Link to="/sportsbook">All Sports</Link> </li>
                                            <li className=""><Link to="/sport-sbook/cricket">Cricket</Link> </li>
                                            <li className=""><Link to="/sport-sbook/soccer">Soccer</Link> </li>
                                            <li className=""><Link to="/sport-sbook/tennis">Tennis</Link> </li>
                                            <div>
                                                <li className="active"><Link to={`/sport-sbook/${sportsId}`}>{sportsId}</Link> </li>
                                                {sereis.length > 0 && sereis?.map((Item) => {
                                                    return <li className="">
                                                        <Link to={`/sport-sbook/${sportsId}/${Item}`}>
                                                            <img className="icon-no_play" src="../assets/images/transparent.gif" />{Item}
                                                        </Link> </li>
                                                })}
                                            </div>
                                            <div></div>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-center ">
                                <div className="theme_tabs">
                  
                        <div className="tab-content">
                            <div role="tabpanel" id="react-aria1396711951-7-tabpane-inplay" aria-labelledby="react-aria1396711951-7-tab-inplay" className="fade tab-pane active show">
                                <div className="theme_accordian">
                                    <div className="accordion">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Cricket</button></h2>
                                            <div className="accordion-collapse collapse show">
                                                <div className="accordion-body">
                                                    <div className="game_table_main_wrapper">
                                                        <div className="game_table_inner">
                                                            <ul className="slip-head d-flex">
                                                                <li className="col-game"></li>
                                                                <li className="col-matched text-end">Matched</li>
                                                                <li className="col-visit text-center">1</li>
                                                                <li className="col-draw text-center">x</li>
                                                                <li className="col-home text-center">2</li>
                                                                <li className="col-info"></li>
                                                            </ul>
                                                            <div className="game-list">
                                                                {renderSports()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div className="col-right">
                    <div className="theme_accordian">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Bet Slip</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bet_slip_data">
                                            <div className="col-right-inner">
                                                <div className="message-bet d-none">
                                                    <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
                                                    <p id="betMsg" className="warning"></p>
                                                </div>
                                                <p className="d-none">Click on the odds to add selections to the betslip.</p>
                                                <div id="betSlipBoard" className="bet_slip">
                                                    <div id="betList" className="slip-list max-heightminus131">
                                                        <div className="bets_list_wrapper"><div></div></div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center align-item-center mt-2 px-3"><p >Click on the odds to add selections to the betslip.</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default BetTechComponent;
