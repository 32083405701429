import { placebetservice } from "../../../services/PostsService"
import { Alert, Button, Modal } from "react-bootstrap";

const BetslipModalComponent = (props) => {
    const {betSelection, setBetSelection, user, bethistory, fetchbetList, showModal, closeModal} =props
    const stackAmount = [10, 50, 100, 200, 1000, 5000]
    const onStack = (stack, type = 'b') => {
        const betObjItem = { ...betSelection }
        betObjItem.stack = type == 'b' ? betObjItem.stack + stack : stack
        const calPnl = calculatePnlF(betObjItem)
        betObjItem.pnl = calPnl.pnl
        betObjItem.exposure = calPnl.exposure
        setBetSelection(betObjItem)
    }
    const calculatePnlF = (betValue) => {
        let pnl = 0,
          exposure = 0
        if (Object.keys(betValue).length > 0) {    
          if (betValue.oddsType == 'bookMaker') {
            if (betValue.isBack) {
              pnl = (betValue.odds / 100) * betValue.stack
              exposure = -1 * betValue.stack
            } else {
              pnl = betValue.stack
              exposure = -1 * ((betValue.odds / 100) * betValue.stack)
            }
          } else if (betValue.marketName.includes('Line')) {
            if (betValue.isBack) {
              pnl = betValue.stack
              exposure = -1 * betValue.stack
            } else {
              pnl = betValue.stack
              exposure = -1 * betValue.stack
            }
          } else if (betValue.betOn =="MATCH_ODDS" || betValue.gtype === 'fancy1') {
            if (betValue.isBack) {
              pnl = betValue.odds * betValue.stack - betValue.stack
              exposure = -1 * betValue.stack
            } else {
              pnl = betValue.stack
              exposure = -1 * (betValue.odds * betValue.stack - betValue.stack)
            }
            console.log(pnl)
          }  else if (betValue.fancystatus == 'yes') {
              //// specific condition for cmeter game
              if (betValue.isBack) {
                pnl = (betValue.volume * betValue.stack) / 100
                exposure = -betValue.stack
              } else {
                exposure = -((betValue.volume * betValue.stack) / 100)
                pnl = betValue.stack
              }
            } else {
              if (betValue.isBack) {
                pnl = betValue.odds * betValue.stack - betValue.stack
                exposure = -1 * betValue.stack
              } else {
                pnl = betValue.stack
                exposure = -1 * (betValue.odds * betValue.stack - betValue.stack)
              }
            }
          }
        return { pnl: parseInt(pnl.toFixed()), exposure: parseInt(exposure.toFixed()) }
      }
    const renderstack = () => {
        const btn = stackAmount.map((Item) => {
            return <li onClick={() => onStack(Item, 'bt')}><button className="price-button-pay-slip">{Item}</button></li>
        })
        return btn
    }
    const placebet = () =>{
        if(!user)
        {
            alert("Please login first")
            return
        }
        if(betSelection.stack<=0){
            alert("please enter stack amount")
            return   
        }
        if(betSelection.stack>0)
        {
            placebetservice(betSelection).then((res)=>{
              setBetSelection({})
              fetchbetList();
              alert("Place bet successfully")
            })
            return
        }

    }
    const onBlurStack = () => {
      onStack(0)
    }
    const onChangeStack = (e) => {
      setBetSelection((prev) => ({ ...prev, stack: parseInt(e.target.value) }));
    }
    return (
      <Modal  show={showModal} onClose={closeModal}>
      <Modal.Header>
      <div class="modal-title h4">Place Bet</div>
        <Button variant="" className="btn-close" onClick={closeModal} type="button"></Button>
      </Modal.Header>
      <Modal.Body style={{padding:"0px"}}>
    <div className="bet_slip_data">
        <div className="col-right-inner">
            {betSelection.selectionId && <>
            <div id="betSlipBoard" className="bet_slip">
                <div id="betList" className="slip-list max-heightminus131">
                    <div className="bets_list_wrapper">
                        <ul className="slip-head d-flex">
                            <li className="col-bet">Back (Bet For)</li>
                            <li id="oddsHeader" className="col-odd">Odds</li>
                            <li id="runsHeader" className="col-odd">Unit:Runs</li>
                            <li className="col-stake">Stake</li>
                            <li className="col-profit text-end">Profit</li>
                        </ul>
                        <div className={`team_list_wrapper ${betSelection.isBack ? 'blue_bg_tuch':'lay_bg_tuch'}`}>
                            <h4><img className="icon-in_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="No_Image" /> {betSelection.matchName}</h4>
                            <div className="match_oddds">
                                <span className="d-flex align-center justify-content-center">
                                    <i className="fa fa-window-close bet-slip-cross-icon"></i>
                                    <h5>{betSelection.selectionName}<small>Match Odds</small></h5>
                                </span>
                                <div className="up_down_rate">
                                    <ul>
                                        <li><input type="text" disabled="" className="bet-slip-first-input" value={betSelection.odds} /></li>
                                        <li><input type="number"
                                         name="back-tik"
                                          max="1500000" 
                                          title="Enter amount"
                                           value={betSelection.stack || ''}
                                           onBlur={onBlurStack}
                                           onChange={ onChangeStack }/></li>
                                    </ul>
                                </div>
                                <div className="odds_value"></div>
                            </div>
                            <div className="preset_value">
                                <ul>
                                    {renderstack()}
                                </ul>
                            </div>
                            <div className="min-bets">Min bet:1</div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className="full_btn">
                <div className="liability sum">Liability<span className="red">${betSelection.exposure}</span></div>
                <ul className="btn-wrap">
                    <li><button className="price-button-pay-slip btn" onClick={()=>setBetSelection({})}>Cancel All</button></li>
                    <li><button className="btn-send" title="" onClick={() => placebet()} >Place Bets</button></li>
                </ul>
               
            </div></>}
        </div>
    </div>
    </Modal.Body>
    </Modal>
    )
}
export default BetslipModalComponent;
