import React from 'react';
import { io } from 'socket.io-client';

// defining the context with empty socket object
export const UserSocketContext = React.createContext({
  socketUser: {},
});

export const WebSocketUserProvider = ({ children }) => {
  const [socket, setSocket] = React.useState({});

  React.useEffect(() => {
    const newSocket = io("https://users.reddyanna.com", {
      transports: ['websocket'],
      autoConnect: true,
      reconnection: true,
    });

    setSocket(newSocket);
  }, []);


  return (
    <UserSocketContext.Provider value={{ socketUser: socket }}>
      {Object.keys(socket).length > 0 && children}
    </UserSocketContext.Provider>
  );
};

// defining a useWebsocketUser hook for functional components
export const useWebsocketUser = () => React.useContext(UserSocketContext);