import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import sportsApi from "../../../../services/SportsService";

const BasicDatatable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);

  const { CurrencySymbol } = GlobalSet();

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
    // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  /*.....income data......*/


  const [income, setIncome] = useState([]);

  const fetchbetList = async () => {
    const response = await sportsApi.get(`get-bet-list?matchId=0&status=all`)
    if (response.data.data) {
      setIncome(response.data.data?.markets)
    }
  }
  useEffect(() => {
    fetchbetList()
  }, [])





  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{t('pro_BetHistory')}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
            <table className="table_caption_style table">
    <thead>
        <tr>
            <th scope="col" className="text-start" width="30%">Market</th>
            <th scope="col" className="text-end" width="">Sports</th>
            <th scope="col" className="text-end" width="">Selection</th>
            <th scope="col" className="text-end" width="">Type</th>
            <th scope="col" className="text-end" width="">Bet ID</th>
            <th scope="col" className="text-end" width="">Bet placed</th>
            <th scope="col" className="text-end" width="">Matched</th>
            <th scope="col" className="text-end" width="">Stake</th>
            <th scope="col" className="text-end" width="">Profit/Loss</th>
            <th scope="col" className="text-end" width="">Status</th>

            <th scope="col" className="text-end" width="">Date matched</th>
        </tr>
    </thead>
    <tbody>
    {income.map((incomerow, index) => (
        <tr>
            <td className="text-start" width="30%">{incomerow.matchName}</td>
            <td className="text-end">{incomerow.sportId==4?"Cricket":(incomerow.sportId==2?'Tennis':"Football")}</td>
            <td className="text-end">{incomerow.selectionName}</td>
            <td className="text-end">{incomerow.isBack?<span className="back-bg">back</span>:<span className="lay-bg">lay</span>}</td>
            <td className="text-end">{incomerow.mb_id}</td>
            <td className="text-end">1</td>
            <td className="text-end">{incomerow.odds}</td>
            <td className="text-end">{incomerow.stack}</td>
            <td className="text-end"><span className="text-success">{incomerow.pnl}</span></td>
            <td className="text-end">{incomerow.status}</td>

            <td>{incomerow.updated_at}</td>
        </tr>))}
    </tbody>
</table>
 
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/bet-history"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/bet-history"
                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                          } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/bet-history"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
