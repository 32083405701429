import axios from 'axios'
import { store } from '../store/store';

export const sportsApi = axios.create({
    baseURL: "https://backoffice.velki.run/api/",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
})

sportsApi.interceptors.request.use((config) => {
    const state = store.getState();
    const auth_token = localStorage.getItem('auth_token');

    const token = state.auth.auth.idToken;
    config.headers = config.headers || {'Content-Type': 'application/json'};
    config.headers.Authorization = auth_token ? `Bearer ${auth_token}` : '';

    return config;
});
export default sportsApi