import { useState } from "react";
import { placebetservice } from "../../../services/PostsService"

const BetSlipComponent = (props) => {
  const { betSelection, setBetSelection, user, bethistory, fetchbetList } = props

  const stackAmount = [10, 50, 100, 200, 1000, 5000]
  const onStack = (stack, type = 'b') => {
    const betObjItem = { ...betSelection }
    betObjItem.stack = type == 'b' ? betObjItem.stack + stack : stack
    const calPnl = calculatePnlF(betObjItem)
    betObjItem.pnl = calPnl.pnl
    betObjItem.exposure = calPnl.exposure
    setBetSelection(betObjItem)
  }
 

 
  const calculatePnlF = (betValue) => {
    let pnl = 0,
      exposure = 0
    if (Object.keys(betValue).length > 0) {
      if (betValue.oddsType == 'bookMaker') {
        if (betValue.isBack) {
          pnl = (betValue.odds / 100) * betValue.stack
          exposure = -1 * betValue.stack
        } else {
          pnl = betValue.stack
          exposure = -1 * ((betValue.odds / 100) * betValue.stack)
        }
      } else if (betValue.marketName.includes('Line')) {
        if (betValue.isBack) {
          pnl = betValue.stack
          exposure = -1 * betValue.stack
        } else {
          pnl = betValue.stack
          exposure = -1 * betValue.stack
        }
      } else if (betValue.betOn == "MATCH_ODDS" || betValue.gtype === 'fancy1') {
        if (betValue.isBack) {
          pnl = betValue.odds * betValue.stack - betValue.stack
          exposure = -1 * betValue.stack
        } else {
          pnl = betValue.stack
          exposure = -1 * (betValue.odds * betValue.stack - betValue.stack)
        }
      } else if (betValue.fancystatus == 'yes') {
        //// specific condition for cmeter game
        if (betValue.isBack) {
          pnl = (betValue.volume * betValue.stack) / 100
          exposure = -betValue.stack
        } else {
          exposure = -((betValue.volume * betValue.stack) / 100)
          pnl = betValue.stack
        }
      } else {
        if (betValue.isBack) {
          pnl = betValue.odds * betValue.stack - betValue.stack
          exposure = -1 * betValue.stack
        } else {
          pnl = betValue.stack
          exposure = -1 * (betValue.odds * betValue.stack - betValue.stack)
        }
      }
    }
    console.log(exposure)

    return { pnl: parseFloat(pnl.toFixed(2)), exposure: parseFloat(exposure.toFixed(2)) }
  }
  const onBlurStack = () => {
    onStack(0)
  }
  const onChangeStack = (e) => {
    setBetSelection((prev) => ({ ...prev, stack: parseInt(e.target.value) }));
  }
  const renderstack = () => {
    const btn = stackAmount.map((Item) => {
      return <li onClick={() => onStack(Item, 'bt')}><button className="price-button-pay-slip">{Item}</button></li>
    })
    return btn
  }
  const placebet = () => {
    if (!user) {
      alert("Please login first")
      return
    }
    if (betSelection.stack <= 0) {
      alert("please enter stack amount")
      return
    }
    if (betSelection.stack > 0) {
      placebetservice(betSelection).then((res) => {
        if (res.data.status == "success") {
          setBetSelection({})
          fetchbetList();
          alert("Place bet successfully")
        } else {
          alert(res.data.message)

        }

      })
      return
    }

  }
  return (<div className="bet_slip_data">
    <div className="col-right-inner">
      {!betSelection.selectionId && <div className="message-bet d-none">
        <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
        <p id="betMsg" className="warning"></p>
      </div>}
      {!betSelection.selectionId && <p className="d-none">Click on the odds to add selections to the betslip.</p>}
      {betSelection.selectionId && <>
        <div id="betSlipBoard" className="bet_slip">
          <div id="betList" className="slip-list max-heightminus131">
            <div className="bets_list_wrapper">
              <ul className="slip-head d-flex">
                <li className="col-bet">Back (Bet For)</li>
                <li id="oddsHeader" className="col-odd">Odds</li>
                <li id="runsHeader" className="col-odd">Unit:Runs</li>
                <li className="col-stake">Stake</li>
                <li className="col-profit text-end">Profit</li>
              </ul>
              <div className={`team_list_wrapper ${betSelection.isBack ? 'blue_bg_tuch' : 'lay_bg_tuch'}`}>
                <h4><img className="icon-in_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="No_Image" /> {betSelection.matchName}</h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i className="fa fa-window-close bet-slip-cross-icon"></i>
                    <h5>{betSelection.selectionName}<small>Match Odds</small></h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li>
                        <input type="text" className="bet-slip-first-input" defaultValue={betSelection.odds} />
                      </li>
                      <li>
                        <input
                          type="number"
                          name="back-tik"
                          max="1500000"
                          title="Enter amount"
                          value={betSelection.stack || ''}
                          onBlur={onBlurStack}
                          onChange={ onChangeStack }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value"></div>
                </div>
                <div className="preset_value">
                  <ul>
                    {renderstack()}
                  </ul>
                </div>
                <div className="min-bets">Min bet:1</div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="full_btn">
          <div className="liability sum">Liability<span className="red">${betSelection.exposure}</span></div>
          <ul className="btn-wrap">
            <li><button className="price-button-pay-slip btn" onClick={() => setBetSelection({})}>Cancel All</button></li>
            <li><button className="btn-send" title="" onClick={() => placebet()} >Place Bets</button></li>
          </ul>
          <ul className="slip-option">
            <li><input type="checkbox" /><label>Please confirm your bets.</label></li>
          </ul>
        </div></>}
      <div className="ovx">
        <table className="table table-bordered">
          <tr className="bet_list_header">
            <th className="text-left"> Runner</th>
            <th> Stake</th>
            <th> Rate</th>
            <th> pl</th>
            <th> Place Date</th>
            <th> Match Date</th>
            </tr>
          {bethistory?.map((Item) => {
            return <tr className={`${Item?.isBack == 1 ? 'back' : 'lay'}`}>
              <td className="no-wrap"> {Item.selectionName} </td>
              <td class="no-wrap"> {Item.stack} </td>
              <td class="no-wrap text-center">  {Item.odds} </td>
              <td class="no-wrap text-center">{Item.isBack==1?Item.pnl:Item.loss} </td>

              <td class="no-wrap"> {Item.created_at} </td><td class="no-wrap"> {Item.matchedDate} </td></tr>
          })}
        </table>
      </div>

    </div>
  </div>
  )
}
export default BetSlipComponent;
