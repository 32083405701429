/* eslint-disable */
import React from 'react'
import { SocketContext } from '../../../context/webSocket'
import { isMobile } from 'react-device-detect'
import FancyList from "./fancy-list";
import SportsDatApi from '../../../services/SportsDataService';

class Fancy extends React.Component {
  static contextType = SocketContext
  context;
  interval;
  // setBetSelection;
  getMarketBook;
  currentMatch;

  constructor(props) {
    super(props)
    const session = props.fancies
    //  setBetSelection = props.setBetSelection
    //  currentMatch = props.currentMatch
    /// getMarketBook = props.getMarketBook
    const updateFancy = {}
    const typesFancy = {}
    session
      .sort((a, b) => a.sr_no - b.sr_no)
      .forEach((fancy) => {
        updateFancy[fancy.marketId] = {
          gtype: fancy.gtype,
          SelectionId: fancy.marketId,
          RunnerName: fancy.fancyName,
        }
        const type = this.FancyBallTypes(fancy.fancyName)
        typesFancy[type] = typesFancy[type] ? [...typesFancy[type], fancy] : [fancy]
      })

    this.state = {
      matchId: props.matchId,
      fancies: typesFancy,
      fancyUpdate: updateFancy,
      getMarketBook: props.getMarketBook,
      currentMatch: props.currentMatch,
      setBetSelection:props.setBetSelection,
    }
  }

  componentDidMount() {
    this.socketEvents()
  }
 
  componentDidUpdate(prevProps) {
    if (prevProps.matchId !== this.props.matchId) {
      this.leaveSocketEvents()
      this.socketEvents()
      clearInterval(this.interval)
    }
    if (prevProps.fancies !== this.props.fancies) {
      const typesFancy = {}
      this.props.fancies.forEach((fancy) => {
        const type = this.FancyBallTypes(fancy.fancyName)
        typesFancy[type] = typesFancy[type] ? [...typesFancy[type], fancy] : [fancy]
      })
      this.setState({ fancies: typesFancy })
    }
  }

  socketEvents = () => {
    this.context.socket.emit('joinRoom', this.props.matchId)
    if (this.props.socketUser) {
      this.props.socketUser.emit('joinRoomMatchIdWUserId', this.props.matchId)
    }
    this.getFancySockets()
    this.addNewFancy()
    this.removeFancy()
    this.suspendedFancy()
    this.context.socket.on('connect', () => {
      this.context.socket.emit('joinRoom', this.props.matchId)
    })
  }

  addNewFancy = () => {
    if (this.props.socketUser) {
      this.props.socketUser.on('addNewFancy', ({ newFancy, fancy }) => {
        const type = this.FancyBallTypes(fancy.RunnerName);
        const allFacies = [...this.state.fancies[type]];
  
        if (this.checkFancyKey(allFacies, fancy) === -1 && this.props.fancyType === newFancy.gtype) {
          const items = { ...this.state.fancyUpdate };
          allFacies.push(newFancy);
          this.setState({
            fancies: {
              ...this.state.fancies,
              [type]: allFacies.sort((a, b) => a.sr_no - b.sr_no),
            },
            fancyUpdate: { ...items, [fancy.SelectionId]: fancy },
          });
        }
      });
    }
  };
  
  removeFancy = () => {
    if (this.props.socketUser) {
      this.props.socketUser.on('removeFancy', (fancy) => {
        const type = this.FancyBallTypes(fancy.RunnerName);
        const allFacies = [...this.state.fancies[type]];
  
        const fancies = allFacies.filter((f) => f.marketId !== fancy.marketId);
        delete this.state.fancyUpdate[fancy.marketId];
        this.setState({
          fancies: { ...this.state.fancies, [type]: fancies },
          fancyUpdate: this.state.fancyUpdate,
        });
      });
    }
  };
  
  suspendedFancy = () => {
    this.context.socket.on('suspendedFancy', ({ fancy, type }) => {
      const fancies = [...this.state.fancies[this.FancyBallTypes(fancy.fancyName)]];
  
      const fanciesIndex = fancies.findIndex(
        (f) => f.marketId === fancy.marketId && f.matchId === fancy.matchId,
      );
  
      if (fanciesIndex !== -1) {
        if (type !== 'active') {
          fancies[fanciesIndex].GameStatus = fancy.GameStatus;
          fancies[fanciesIndex].isSuspend = fancy.isSuspend;
        } else {
          fancies[fanciesIndex].active = fancy.active;
        }
        this.setState({
          fancies: { ...this.state.fancies, [type]: fancies },
          fancyUpdate: this.state.fancyUpdate,
        });
      }
    });
  };
  leaveSocketEvents = () => {
    this.context.socket.off('addNewFancy')
    this.context.socket.off('removeFancy')
    this.context.socket.off('getFancyData')
    this.context.socket.off('suspendedFancy')
    this.context.socket.emit('leaveRoom', this.props.matchId)
  }

  getFancySockets() {
    const handler = () => {
      SportsDatApi.get(`fancy-data?MatchID=${+this.props.matchId}`).then(({ data }) => {
        const fancies = data.data.reduce((acc, fancy) => {
          acc[fancy.SelectionId] = { ...this.state.fancyUpdate[fancy.SelectionId], ...fancy }
          return acc
        }, {})
        this.setState({ fancyUpdate: fancies })
      })
    }
    this.interval = setInterval(handler, 500)
  }

  FancyBallTypes(fancyName) {
    let type = 'blank'
    if (fancyName) {
      if (fancyName.includes(' ball run ')) {
        type = 'ballRun'
      }
      if (/^Only/.test(fancyName) || fancyName.includes(' ball No ')) {
        type = 'overBallNo'
      }
    }
    return type
  }

  onFancyType = (type) => {
    this.props.setFancyType(type)
    this.setState({ fancies: {}})
  }

  fancyMenu = (fancyType) => {
    const menus = [
      { type: 'session', label: 'Session', width: isMobile ? '19%' : '11%' },
      { type: 'fancy1', label: 'Fancy1', width: isMobile ? '19%' : '11%' },
    ]

    return menus.map((menu) => (
      <li
        key={menu.type}
        onClick={() => this.onFancyType(menu.type)}
        className='nav-item'
      >
        <button type="button" class={`nav-link ${fancyType === menu.type ? 'active' : ''}`}>
           {menu.label}
        </button>
      </li>
    ))
  }

  render() {
    const { fancies, matchId, fancyUpdate } = this.state
    const clsgrid = isMobile ? 'col-12' : 'col-6'
    const { fancyType } = this.props

    return (
      <>
      <div className="sportsbook-head  mt-3 d-flex align-items-center ">
        <span className="winner-sportsbook" >
          <h4 className="in-play mb-0"><span id="sportsBookName">Fancy Bet</span>
          <div className="info-popover"></div></h4>
          </span>
          </div>
          <div class="premier-tab fancy-premier-tab">
            <ul class="mb-1 nav nav-tabs" role="tablist">
          {this.fancyMenu(fancyType)}
        </ul>
       
        <div className='tab-content'>
          <div className='fade tab-pane active show'>
            <div className='special-bet-table'>
              <div className='space-bet'>
              <div className="special_bet"><h3 class="mb-0"><a class="add-pin" title="Add to Multi Markets" href="/"></a>Fancy Bet</h3></div>
              </div>
            </div>
           
            {fancies && <FancyList fancies={fancies.blank} fancyUpdate={fancyUpdate} getCurrentMatch={this.state.currentMatch} getMarketBook={this.state.getMarketBook}
            setBetSelection={this.state.setBetSelection} onBet={this.props.onBet}/>}
          
          </div>
        </div>
        </div>
      </>
    )
  }
}
export default Fancy
