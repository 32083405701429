import React from 'react';
import { isMobile } from 'react-device-detect';
import { getCustIpAddress } from '../../../services/AuthService';
import PnlMarket from './pnl';


const FancyList = React.memo(({ fancies, fancyUpdate, getCurrentMatch, getMarketBook, setBetSelection, onBet }) => {
 

    return (
        <table className='fancy-w-tab table'>
            <tbody>
                <tr className="bet-all bet-thead"><td colSpan="3" width="60%"></td><td className="p-0 border-0"><table><tr><td width="8%"><strong className="text-center d-block">No</strong></td><td width="8%"> <strong className="text-center d-block">Yes</strong></td></tr></table></td><td colSpan="2"></td></tr>
                {fancies?.length > 0 &&
                    fancies.map((fancy) => {
                        // if (!fancy?.active) return null;
                        let updatedFancy = fancyUpdate[fancy.SelectionId] || {};
                        if (updatedFancy.LayPrice1 === undefined) return null;
                        return <>
                            <tr key={fancy.fancy_id} className='bet-all  bet-thead'>
                                <td className='predict mobile_large'>
                                    <p style={{marginBottom:"0px"}} className='fancy-name'>{fancy.RunnerName}</p>
                                    <PnlMarket marketBook={getMarketBook} selectionId={fancy.SelectionId} marketId={getCurrentMatch.match_api_id} />

                                </td>
                                <td colspan="2" class=""></td>
                                <td className="for_saspend" style={{padding:"0px", width:"100%",padding:"0px", margin:"0px"}}>
                                    <table>
                                        <tr>
                                            <td className="ps-0" style={{ position: 'relative' }} onClick={() => onBet(false, { ...fancy, ...updatedFancy }, 0, { ...fancy, ...updatedFancy }, { ...fancy, ...updatedFancy })}>
                                                <dl className="lay-gradient">
                                                    <dd
                                                        id="lay_1"
                                                        className="p-0"
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: 'rgb(250, 169, 186)',
                                                            color: 'rgb(0, 0, 0)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <p className="border-0 p-0 mt-0 rounded-0">{updatedFancy.LayPrice1}</p>
                                                        <p
                                                            className="border-0 p-0 mt-0 rounded-0"
                                                            style={{ fontSize: '11px', fontWeight: 'normal' }}
                                                        >
                                                            {updatedFancy.LaySize1}
                                                        </p>
                                                    </dd>
                                                </dl>
                                            </td>
                                            <td className="ps-0" style={{ position: 'relative' }} onClick={() => onBet(true, { ...fancy, ...updatedFancy })}>
                                                <dl className="back-gradient">
                                                    <dd
                                                        id="back_1"
                                                        className="px-0"
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: 'rgb(114, 187, 239)',
                                                            color: 'rgb(0, 0, 0)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <p className="border-0 p-0 mt-0 rounded-0">{updatedFancy.BackPrice1}</p>
                                                        <p
                                                            className="border-0 p-0 mt-0 rounded-0"
                                                            style={{ fontSize: '11px', fontWeight: 'normal' }}
                                                        >
                                                            {updatedFancy.BackSize1}
                                                        </p>
                                                    </dd>
                                                </dl>
                                            </td>
                                        </tr>
                                    </table>
                                    {(updatedFancy.gtstatus=="SUSPENDED" || updatedFancy.gtstatus=="Ball Running") && <dd  id="suspend" className="suspend-fancy" style={{display:"flex",height:"60px", padding:"0px", width:"100%",padding:"0px", margin:"0px"}}><p id="info"> {updatedFancy.gtstatus}</p></dd>}
                                </td>
                                <td class="predict">
                                    <div class="d-flex flex-column h-100 justify-center min-max-rank">
                                        <span class="d-block">Min/Max</span><small>{fancy.min} / {fancy.max}</small></div>
                                </td>
                            </tr>
                            {updatedFancy.rem && (<tr className='bet-all  bet-thead'>
                                <td class="predict mobile_large"> {updatedFancy.rem}  </td>
                            </tr>)}
                        </>

                    })}


                {fancies?.length <= 0 && (
                    <tr className='bet-all  bet-thead'>
                        <td class="predict mobile_large"> No Real time Fancy Found  </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
});

export default FancyList