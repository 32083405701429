import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import swal from "sweetalert";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
  switch (action.type){
    case 'sendMessage':
      return { ...state, sendMessage: !state.sendMessage }    
    case 'postModal':
      return { ...state, post: !state.post }
    case 'linkModal':
      return { ...state, link: !state.link }    
    case 'cameraModal':
      return { ...state, camera: !state.camera }    
    case 'replyModal':
      return { ...state, reply: !state.reply }
    default:
      return state  
  } 
}

  const AppProfile = () => {
  const history = useHistory();
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  
  if (auth_token !== null) {
    const params = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9', // Add your key here
      currency: selectedCurrency, // Add the currency parameter
    };

    axios.get('/home-data', { params })
      .then(response => {
          console.log("done.");
         })
      .catch(error => {
        console.log(error);
      });
  }


  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
          console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                //swal("Success",res.data.message,"success");
                //history.push('/');
               swal("Success", res.data.message, "success");
               window.location.href = '/'; 
            }
        });
    }
      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401)
        {
          localStorage.removeItem('auth_token');
            swal("Unauthorized",err.response.data.message,"warning");
            history.push('/');
        }
        return Promise.reject(err);
    });


  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
    useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/',data)
         .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])

  let picture_Url;
  let Email;
  let Username;
  let mobile_No;
  let name;
  let balance;
  let bonus_balance;
  let total_deposit;
  let total_Income;
  let total_withdraw;
  let affiliate_earning;
  if (user == "") {
    console.log(user);
  }else{

    Email=user.data.email;
    if(user.data.picture_Url === '0'){
      picture_Url = profile;
    }else{
      picture_Url = BaseUrl+user.data.picture_Url;
    }
    name=user.data.name;
    mobile_No=user.data.mobile_No;
    Username=user.data.username;
    balance=user.data.balance;
    bonus_balance=user.data.bonus_balance;
    total_deposit=user.data.total_deposit;
    total_Income=user.data.total_Income;
    total_withdraw=user.data.total_withdraw;
    affiliate_earning=user.data.affiliate_earning;

  }
    
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [SetYouImg, AYouImage] = useState(null)
  const [ggs_photo, ApiYouImage] = useState([]);

  const [registerInput, setRegister] = useState({
    your_image:'',
      error_list: [],
  });

  const YourImage = (event) => {
   if (event.target.files && event.target.files[0]) {
     AYouImage(URL.createObjectURL(event.target.files[0]));
     ApiYouImage({your_image: event.target.files[0] });
     setShowText(true);
   }
  }

  const [errorlist, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showText, setShowText] = useState(false);
  //form submit handler
  const submitProduct=(e) =>{
    if(!loading){
    setLoading(true);
      e.preventDefault();
      const formData= new FormData();
      formData.append('key_s', "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9");
      formData.append('your_image', ggs_photo.your_image);
      axios.post('/change-profile-photo', formData)
      .then((res)=>{
      setLoading(false);
            if(res.data.status === 'success')
            {
                swal("Success",res.data.message,"success");
            }
           else if(res.data.status === 'fail')
            {
                swal("Warning",res.data.message,"warning");
            }
            else
            {
                setRegister({...registerInput, error_list: res.data.validation_error});
            }
        setLoading(false);
        setShowText(false);
      });
  }
  }

  return (
    <Fragment>

        <div className="row">
          <div className="col-xl-12 col-lg-4">
            <div className="email-left-box card">
            <div className="m-2">

              <div className="profile-info d-flex justify-content-center">
                <form onSubmit={submitProduct} enctype= "multipart/form-data">
                    <div className="profile-photo">
                      <img
                        src={SetYouImg || picture_Url || profile}
                        className="img-fluid rounded-circle"
                        alt="profile"
                        style={{ width: '60px' }}
                      />
                    </div>
                {showText && (
                <button type="submit" className="btn btn-primary sw-btn-next ms-1 mt-1">{loading ? t('comon_Submitting') : t('comon_Submit')}</button>
                )}                  
                </form>

              </div>

              <div className="mail-list rounded mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Wallet
                </Link>
                <Link to="/app-profile" className="list-group-item text-black">
                <i className="fas fa-donate font-18 align-middle me-2 text-warning"></i>
                My Profile
                </Link>
                <Link to="/deposit" className="list-group-item text-black">
                <i className="fas fa-credit-card font-18 align-middle me-2 text-warning"></i>
                Deposit
                </Link>
                <Link to="/withdraw" className="list-group-item text-black">
                <i className="fas fa-archive  font-18 align-middle me-2 text-warning"></i>
                Withdrawal
                </Link>
                <Link to="/affiliate" className="list-group-item text-black">
                <i className="fas fa-archive  font-18 align-middle me-2 text-warning"></i>
                Affiliate Earning
                </Link>
              </div>


              <div className="mail-list rounded  mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Report
                </Link>
{/*             <Link to="/income-history" className="list-group-item text-black">
                <i className="fas fa-file-alt font-18 align-middle me-2 text-warning"></i>
                Income History
                </Link>*/}
                <Link to="/transaction-history" className="list-group-item text-black">
                <i className="mdi mdi-file-document-box font-18 align-middle me-2 text-warning"></i>
                Transaction History
                </Link>
                <Link to="/account-history" className="list-group-item text-black">
                <i className="mdi mdi-file-document-box font-18 align-middle me-2 text-warning"></i>
                Account Statement
                </Link>
                <Link to="/bet-history" className="list-group-item text-black">
                <i className="mdi mdi-file-document-box font-18 align-middle me-2 text-warning"></i>
                Bet History
                </Link>
              </div>

              <div className="mail-list rounded  mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Support
                </Link>
                <Link  to="/support-ticket" className="list-group-item text-black">
                <i className="fas fa-bullhorn font-18 align-middle me-2 text-warning"></i>
                Support Ticket
                </Link>
              </div>



              <div className="mail-list rounded  mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Security
                </Link>
                <Link  to="/security" className="list-group-item text-black">
                <i className="fas fa-bullhorn font-18 align-middle me-2 text-warning"></i>
                Security
                </Link>
              </div>


              <div className="mail-list rounded  mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Account Security
                </Link>
                <Link to="/change-password" className="list-group-item text-black">
                <i className="fa fa-key font-18 align-middle me-2 text-warning"></i>
                Change Password
                </Link>
              </div>





              <div className="mail-list rounded  mt-4">
                <Link style={{ background: '#ffbf19'}} className="list-group-item text-black">
                <i className="fas fa-star font-18 align-middle me-2 text-dark"></i>
                Logout
                </Link>
                <Link onClick={logoutSubmit} className="list-group-item text-black">
                <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger me-1" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line></svg>  
                Logout
                </Link>
              </div>



            </div>
            </div>
          </div>
        </div>





    </Fragment>
  );
};

export default AppProfile;




































