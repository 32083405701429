import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";


/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import Setting from "./layouts/Setting";
import Registration from "./pages/Registration";
import ForgotPassword from "./components/AppsMenu/public/ForgotPassword";
import ForgotChangePassword from "./components/AppsMenu/public/ForgotChangePassword";


/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";

/// pages
import viewpage from "./components/AppsMenu/public/viewpage";
import Bonus from "./components/AppsMenu/public/Bonus";
import BonusOpen from "./components/AppsMenu/public/BonusOpen";

/// App
import Security from "./components/AppsMenu/AppProfile/Security";
import Menu from "./components/AppsMenu/AppProfile/Menu";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import IncomeHistory from "./components/AppsMenu/AppProfile/IncomeHistory";
import TransactionHistory from "./components/AppsMenu/AppProfile/TransactionHistory";
import AccountHistory from "./components/AppsMenu/AppProfile/AccountHistory";
import BetHistory from "./components/AppsMenu/AppProfile/BetHistory";


import MyBetHistory from "./components/AppsMenu/AppProfile/MyBetHistory";
import Affiliate from "./components/AppsMenu/AppProfile/Affiliate";
import Deposit from "./components/AppsMenu/AppProfile/Deposit";
import LocalDepositView from "./components/deposit/LocalDepositView";
import LocalAutoDepositView from "./components/deposit/LocalAutoDepositView";
import CryptoDepositView from "./components/deposit/CryptoDepositView";
import Withdraw from "./components/AppsMenu/AppProfile/Withdraw";
import AgWithdraw from "./components/AppsMenu/AppProfile/AgWithdraw";
import ChangePassword from "./components/AppsMenu/AppProfile/ChangePassword";
import IdentityVerify from "./components/AppsMenu/AppProfile/IdentityVerify";

//SupportTicket
import SupportTicket from "./components/Ticket/SupportTicket";
import CreateTicket from "./components/Ticket/CreateTicket";
import ViewTicket from "./components/Ticket/ViewTicket";

//casino game
import CaPopular from "./components/Casino/CaPopular";
import CasinoGetOne from "./components/Casino/CasinoGetOne";
import CasinoProviders from "./components/Casino/CasinoProviders";
import SportsBook from "./components/sportsbook/SportsBook";
import SportsBookList from "./components/sportsbook/SportsBookList";

import { ThemeContext } from "../context/ThemeContext";
import Odds from "./components/sportsbook/odds";
import { isMobile } from "react-device-detect";

const Markup = () => {
  const auth_token = localStorage.getItem('auth_token');
  const { menuToggle } = useContext(ThemeContext);


  const publicroutes = [
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "view-page/:id/:titel", component: viewpage }, 
    { url: "register", component: Registration },
    { url: "forgot-password", component: ForgotPassword },
    { url: "forgot-change-password", component: ForgotChangePassword },
    ///Casino
    { url: "home-game/:id", component: CaPopular }, 
    { url: "casino-game-get/:id/:name", component: CasinoGetOne },  
    { url: "casino-providers", component: CasinoProviders },
    { url: "sportsbook", component: SportsBook },
    { url: "sportsbook/:sportsId", component: SportsBook },
    { url: "markets/:matchId", component: Odds },
    { url: "bonus", component: Bonus },
    { url: "bonus-open/:slug", component: BonusOpen },
  ];


  const authroutes = [
  /// Apps
    
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "view-page/:id/:titel", component: viewpage }, 
    { url: "register", component: Registration },
    ///Casinozz
    { url: "home-game/:id", component: CaPopular }, 
    { url: "casino-game-get/:id/:name", component: CasinoGetOne },  
    { url: "casino-providers", component: CasinoProviders },
    { url: "bonus", component: Bonus },
    { url: "bonus-open/:slug", component: BonusOpen },

    { url: "menu", component: Menu },
    { url: "security", component: Security },
    { url: "app-profile", component: AppProfile },
    { url: "income-history", component: IncomeHistory },
    { url: "transaction-history", component: TransactionHistory },
    { url: "account-history", component: AccountHistory },
    { url: "bet-history", component: BetHistory },

    { url: "mybet-history", component: MyBetHistory },
    { url: "affiliate", component: Affiliate },
    { url: "deposit", component: Deposit },
    { url: "local-deposit-view/:id/:slug", component: LocalDepositView },
    { url: "local-auto-deposit-view/:id/:slug", component: LocalAutoDepositView },
    { url: "crypto-deposit-view/:id/:slug", component: CryptoDepositView },
    { url: "withdraw", component: Withdraw },
    { url: "ag-withdraw", component: AgWithdraw },
    { url: "change-password", component: ChangePassword },
    { url: "identity-verification", component: IdentityVerify },

    { url: "sportsbook", component: SportsBook },
    { url: "sport-sbook/:sportsId", component: SportsBookList },
    { url: "sport-sbook/:sportsId/:seriesname", component: SportsBookList },

    { url: "markets/:matchId", component: Odds },
    //support-ticket
    { url: "support-ticket", component: SupportTicket },
    { url: "create-ticket", component: CreateTicket },
    { url: "view-ticket/:id", component: ViewTicket },
  ];




  if (auth_token !== null) {
   var routes = authroutes;
  }else{
   var routes = publicroutes;
  }

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  console.log(path, "path")
  let type =  window.location.pathname.includes("sportsbook")
  type =  window.location.pathname.includes("markets") || type
    console.log(type)
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div style={{marginLeft : '0px'}} className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? (!type || !isMobile ? "container-fluid": "") : ""}`}
            style={{ paddingTop: '0px'}}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {(!pagePath || type) && <Footer />}
      </div>
      <Setting />
    <ScrollToTop />
    </>
  );
};

export default Markup;
