import React, { useCallback, useEffect, useState } from "react";
import { useParams, Router, useLocation } from "react-router-dom";
import { sportsApi } from "../../../services/SportsService";
import { useWebsocket } from "../../../context/webSocket";
import moment from 'moment';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// Get the current date and time
const now = moment();
console.log("Current Date and Time:", now.format('YYYY-MM-DD HH:mm:ss'));

// Check if a date is before or after another
const matchDate = "2025-01-05T22:00:00.000Z";
console.log("Is match live?", moment().isBetween(matchDate, moment(matchDate).add(2, 'hours')));

const BetTechComponent = () => {
    const type = useParams();
    const [activetab, setactivetab] = useState("Inplay")
    const [matchList, setMatchList] = useState({ 4: [], 1: [], 2: [] })
    const { socket } = useWebsocket()
    const [odds, setOdds] = React.useState({})
    const { sportsId, status } = useParams()
    console.log(sportsId)
    const isEventLive = (matchDateTime, durationHours = 2) => {
        const now = new Date(); // Current time
        const startTime = new Date(matchDateTime); // Event start time  
        return now >= startTime;
    }
    const fetchMatchList = async () => {
        let convertSportId = ""
        if(sportsId)
        {
            convertSportId = sportsId=="cricket" ? 4 : convertSportId
            convertSportId = sportsId=="soccer" ? 1 : convertSportId
            convertSportId = sportsId=="tennis" ? 2 : convertSportId
        }
        const response = await sportsApi.get(`get-match?sportId=${sportsId}&status=all&limit=`)
        if (response.data.data) {
            const filterData = { 4: [], 1: [], 2: [] }
            response.data.data?.map((Item) => {
                Item["isLive"] = isEventLive(Item.match_datetime);
            if(!sportsId || Item.match_sports_id==convertSportId)
            {
                if (Item["isLive"] && activetab == 'Inplay') {
                    filterData[Item.match_sports_id].push(Item)
                }
                if (!Item["isLive"] && activetab == 'Today' && moment(Item.match_datetime).format("YYYY-MM-DD")<=moment().format("YYYY-MM-DD")) {
                    filterData[Item.match_sports_id].push(Item)
                }
                if (!Item["isLive"] && activetab == 'tomorrow' && moment(Item.match_datetime).format("YYYY-MM-DD")!=moment().format("YYYY-MM-DD")) {
                    filterData[Item.match_sports_id].push(Item)
                }
            }
            })
            const oddsData = { ...odds };
            marketIdsEvent(response.data.data, oddsData, 'joinMarketRoom');
            setOdds(oddsData);
            setMatchList(filterData)
        }
    }
    useEffect(() => {
        fetchMatchList()
        return () => {
            const oddsData = { ...odds };
            marketIdsEvent(matchList, oddsData, 'leaveMarketRoom');
        };
    }, [activetab, sportsId])

    const marketIdsEvent = (data, oddsData, event) => {
        if (data && data?.length > 0) {
            data?.map((match) => {
                oddsData[match.market_id] = Array(6).fill('-')
                if (match.market_id) {
                    socket.emit(event, match.market_id)
                }
            })
        }

    }

    React.useEffect(() => {
        socket.on('getMarketData', (marketData) => {
            let firstIndexFirst = '-'
            let firstIndexTwo = '-'
            let secIndexFirst = '-'
            let secfirstIndexTwo = '-'
            let thirdIndexFirst = '-'
            let thirdfirstIndexTwo = '-'
            if (marketData.runners) {
                if (marketData.runners[0] && marketData.runners[0].ex.availableToBack[0]) {
                    firstIndexFirst = marketData.runners[0].ex.availableToBack[0].price
                    firstIndexTwo = marketData.runners[0].ex.availableToLay[0].price
                }

                if (marketData.runners[1] && marketData.runners[1].ex.availableToBack[0]) {
                    secIndexFirst = marketData.runners[1].ex.availableToBack[0].price
                    secfirstIndexTwo = marketData.runners[1].ex.availableToLay[0].price
                }

                if (marketData.runners[2] && marketData.runners[2].ex.availableToBack[0]) {
                    thirdIndexFirst = marketData.runners[2].ex.availableToBack[0].price
                    thirdfirstIndexTwo = marketData.runners[2].ex.availableToLay[0].price
                }
            }

            setOdds((prev) => ({
                ...prev,
                [marketData.marketId]: [
                    firstIndexFirst,
                    firstIndexTwo,
                    thirdIndexFirst,
                    thirdfirstIndexTwo,
                    secIndexFirst,
                    secfirstIndexTwo,
                ]
            }))
        })
        return () => {
            socket.off('getMarketData')
        }
    }, [odds])

    const memoOdds = useCallback(
        (marketId) => {
            console.log(odds)
            const marketData = odds[marketId]
            return (
                <>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[0]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[1]}</button>
                    </dd>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[2]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[3]}</button>
                    </dd>
                    <dd className="col-draw">
                        <button id="back_odds_undefined" className="btn-back btn-back-new &quot; + false" >{marketData && marketData[4]}</button>
                        <button id="back_odds_undefined" className="btn-lay btn-back-new &quot; + false" >{marketData && marketData[5]}</button>
                    </dd>
                </>
            )
        },
        [odds],
    )
    const renderSports = (sportId) => {
        return matchList[sportId]?.map((Item) => {
            return <dl className="game-list-col d-flex">
                <dt className="col-matched">
                    <Link to={`/markets/${Item.match_api_id}`}>
                        <img className={`${Item.isLive ? 'icon-in_play' : ''}`} src="../../imgs/transparent.gif" alt="No_Image" />
                        <span className="game-titlesmall">{Item.match_name}</span>
                        {Item.isLive && <><span className="in_play">In-Play </span><span className="game-live"></span></>}
                        {!Item.isLive && <span class="game-list-time">{moment(Item.match_datetime).format("YYYY-MM-DD HH:mm")}</span>}
                    </Link>
                </dt>
                <dd className="col-mdl text-end">USD 0</dd>
                {memoOdds(Item?.market_id)}
                <dd className="col-info"><a className="add-pin" href="/inplay"></a></dd>
            </dl>
        })
    }
    return (
        <div>
            <div className="full-wrap without-left-nav inplay_page_wrap">
                <div className="col-center inplay">
                    <div className="theme_tabs">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" role="presentation" onClick={() => setactivetab("Inplay")}>
                                <button type="button" id="react-aria1396711951-7-tab-inplay" role="tab" data-rr-ui-event-key="inplay" aria-controls="react-aria1396711951-7-tabpane-inplay" aria-selected="true" className={`nav-link ${activetab == 'Inplay' ? 'active' : ''}`}>Inplay</button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setactivetab("Today")}>
                                <button type="button" id="react-aria1396711951-7-tab-today" role="tab" data-rr-ui-event-key="today" aria-controls="react-aria1396711951-7-tabpane-today" aria-selected="false" tabindex="-1" className={`nav-link ${activetab == 'Today' ? 'active' : ''}`}>Today</button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setactivetab("tomorrow")}>
                                <button type="button" id="react-aria1396711951-7-tab-tomorrow" role="tab" data-rr-ui-event-key="tomorrow" aria-controls="react-aria1396711951-7-tabpane-tomorrow" aria-selected="false" tabindex="-1" className={`nav-link ${activetab == 'tomorrow' ? 'active' : ''}`}>
                                    Tomorrow
                                </button>
                            </li>


                            <Link className="nav-item" to={localStorage.getItem('auth_token') ? "/bet-history" : "/?login=true"}>
                                <li role="presentation">
                                    <button
                                        type="button"
                                        id="react-aria1396711951-7-tab-tomorrow"
                                        role="tab"
                                        data-rr-ui-event-key="tomorrow"
                                        aria-controls="react-aria1396711951-7-tabpane-tomorrow"
                                        aria-selected="false"
                                        tabIndex="-1"
                                        className={`nav-link`}
                                    >
                                        Result
                                    </button>
                                </li>
                            </Link>                            
                        </ul>
                        <div className="tab-content">
                            <div role="tabpanel" id="react-aria1396711951-7-tabpane-inplay" aria-labelledby="react-aria1396711951-7-tab-inplay" className="fade tab-pane active show">
                                <div className="theme_accordian">
                                    <div className="accordion">
                                        {(!sportsId || sportsId=='cricket') && <div className="accordion-item">
                                            <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Cricket</button></h2>
                                            <div className="accordion-collapse collapse show">
                                                <div className="accordion-body">
                                                    <div className="game_table_main_wrapper">
                                                        <div className="game_table_inner">
                                                            <ul className="slip-head d-flex">
                                                                <li className="col-game"></li>
                                                                <li className="col-matched text-end">Matched</li>
                                                                <li className="col-visit text-center">1</li>
                                                                <li className="col-draw text-center">x</li>
                                                                <li className="col-home text-center">2</li>
                                                                <li className="col-info"></li>
                                                            </ul>
                                                            <div className="game-list">
                                                                {renderSports(4)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {(!sportsId || sportsId=='tennis') && <div className="accordion-item">
                                            <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Tennis</button></h2>
                                            <div className="accordion-collapse collapse show">
                                                <div className="accordion-body">
                                                    <div className="game_table_main_wrapper">
                                                        <div className="game_table_inner">
                                                            <ul className="slip-head d-flex">
                                                                <li className="col-game" ></li>
                                                                <li className="col-matched text-end">Matched</li>
                                                                <li className="col-visit text-center">1</li>
                                                                <li className="col-home text-center">2</li>
                                                                <li className="col-info"></li>
                                                            </ul>
                                                            <div className="game-list">
                                                                {renderSports(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        {(!sportsId || sportsId=='soccer') && <div className="accordion-item">
                                            <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Soccer</button></h2>
                                            <div className="accordion-collapse collapse show">
                                                <div className="accordion-body">
                                                    <div className="game_table_main_wrapper">
                                                        <div className="game_table_inner">
                                                            <ul className="slip-head d-flex">
                                                                <li className="col-game"></li>
                                                                <li className="col-matched text-end">Matched</li>
                                                                <li className="col-visit text-center">1</li>
                                                                <li className="col-draw text-center">x</li>
                                                                <li className="col-home text-center">2</li>
                                                                <li className="col-info"></li>
                                                            </ul>
                                                            <div className="game-list">
                                                                {renderSports(1)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                <div className="col-right">
                    <div className="theme_accordian">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header"><button type="button" aria-expanded="true" className="accordion-button">Bet Slip</button></h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="bet_slip_data">
                                            <div className="col-right-inner">
                                                <div className="message-bet d-none">
                                                    <h4 id="errorMsg" className="error">Your back odds cannot bet less than or the same as your lay odds for Martinez v S Kwon, Match Odds, Soonwoo Kwon</h4>
                                                    <p id="betMsg" className="warning"></p>
                                                </div>
                                                <p className="d-none">Click on the odds to add selections to the betslip.</p>
                                                <div id="betSlipBoard" className="bet_slip">
                                                    <div id="betList" className="slip-list max-heightminus131">
                                                        <div className="bets_list_wrapper"><div></div></div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center align-item-center mt-2 px-3"><p >Click on the odds to add selections to the betslip.</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default BetTechComponent;
