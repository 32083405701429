import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import sportsApi from "../../../../services/SportsService";

const BasicDatatable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  //const [test, settest] = useState(0);

  const { CurrencySymbol } = GlobalSet();

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
    // chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };



  /*.....income data......*/


  const [income, setIncome] = useState([]);
  const fetchbetList = async () => {
      const response = await sportsApi.get(`get-account-statement`)
      if (response.data.data) {
        setIncome(response.data.data?.markets)
      }
    }
  useEffect(() => {
    fetchbetList()

  }, [])





  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{t('pro_AccountHistory')}</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="table_caption_style table "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                   <thead>
                    <tr>
                      <th scope="col" className="text-start">Date</th>
                      <th scope="col" className="text-end">Transaction No</th>
                      <th scope="col" className="text-end">Debits</th>
                      <th scope="col" className="text-end">Credits</th>
                      <th scope="col" className="text-end">Balance</th>
                      <th scope="col" className="text-end">Remarks</th>
                    </tr>
                  </thead>

 
                <tbody>
                  {income.map((incomerow, index) => (
                    <tr key={incomerow.id}>
                    <td>{incomerow.created_at}</td>
                    <td className="text-center">{incomerow.id}</td>
                    <td className="text-end"><span class="text-success" style={{fontWeight:"bold"}}>{incomerow.amount>=0 ?  CurrencySymbol + parseFloat(parseFloat(incomerow.amount).toFixed(4)): "-"}</span></td>
                    <td className="text-end"><span class="text-danger" style={{fontWeight:"bold"}}>{incomerow.amount<0 ? CurrencySymbol + parseFloat(parseFloat(incomerow.amount).toFixed(4)) : "-"}</span></td>
                    <td className="text-end"><span class="text-success" style={{fontWeight:"bold"}}>{CurrencySymbol + parseFloat(parseFloat(incomerow.close_bal).toFixed(4))}</span></td>
                    <td className="text-end">{incomerow.narration}</td>
                     </tr>
                  ))}
                </tbody>

              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/account-history"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/account-history"
                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                          } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/account-history"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
